import { useState } from "react";
import { Links, Wrapper } from "./menu.e";
import { NavLink } from "react-router-dom";
import { CgArrowLeft } from "react-icons/cg";
import { BiBuildings, BiGitBranch, BiSupport, BiReceipt } from "react-icons/bi";
import { FiUserPlus, FiUsers } from "react-icons/fi";
import {
  TbDeviceDesktopAnalytics,
  TbCreditCard,
  TbLogout,
} from "react-icons/tb";
import Logo from "../../assets/images/logo.svg";
import Button from "../../components/common/button/button";

function Menu() {
  const [left, setLeft] = useState(window.innerWidth > 1200 ? true : false);

  return (
    <Wrapper className={left === false ? "hidden" : ""}>
      <img src={Logo} alt="Incom admin" />
      <Links>
        <NavLink
          to="/organization"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <BiBuildings fontSize={22} />
          Organizations
        </NavLink>
        <NavLink
          to="/clients"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <FiUsers fontSize={22} />
          Clients
        </NavLink>
        <NavLink
          to="/services"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
        >
          <BiGitBranch fontSize={22} />
          Services
        </NavLink>
        <NavLink
          to="/pos-devices"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
        >
          <TbDeviceDesktopAnalytics fontSize={22} />
          Pos devices
        </NavLink>
        <NavLink
          to="/moderators"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
        >
          <BiSupport fontSize={22} />
          Moderators
        </NavLink>
        <NavLink
          to="/transactions"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
        >
          <TbCreditCard fontSize={22} />
          Transactions
        </NavLink>
        <NavLink
          to="/receipts"
          onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
        >
          <BiReceipt fontSize={22} />
          Receipts
        </NavLink>
      </Links>
      <NavLink
        to="/create-client"
        onClick={() => (window.innerWidth < 1200 ? setLeft(false) : "")}
      >
        <FiUserPlus fontSize={22} />
        Create Client
      </NavLink>
      <NavLink
        onClick={() => {
          localStorage.removeItem("user");
          window.location.reload(false);
        }}
        style={{ marginTop: "auto" }}
        to="/logout"
      >
        <TbLogout fontSize={22} />
        Logout
      </NavLink>
      <Button
        onClick={() => setLeft(!left)}
        text={<CgArrowLeft fontSize={20} />}
      />
    </Wrapper>
  );
}

export default Menu;
