import styled, { createGlobalStyle } from "styled-components";
import CheckboxIcon from "../../../assets/images/checkbox.svg";

export const GlobalStyle = createGlobalStyle`
    :root {
        --input-bg: #fff;
        --input-border: #ced4da;
        --main: #74b62e;
        --gray: #8e8e8e;
        --text: #1B212D;
        --blue: #4318FF;
        --indigo: #405189;
        --purple: #6559cc;
        --pink: #f672a7;
        --red: #f06548;
        --orange: #f1963b;
        --yellow: #f7b84b;
        --green: #0ab39c;
        --teal: #02a8b5;
        --cyan: #299cdb;
        --white: #fff;
        --gray: #878a99;
    }
    html {
        scroll-behavior: smooth;
    }
    body {
        background-color: #f4f7fe;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "Poppins", sans-serif;
        user-select: none;
        -webkit-user-drag: none;
    }
    button, input {
        border: none;
        outline: none;
        background: none;
    }
    a {
        text-decoration: none;
    }
    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: var(--blue);
    }
    .input {
        display: block;
        width: 100%;
        padding: 15px 24px;
        font-size: 10px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.3px;
        transition: 0.2s all ease-in-out;
        letter-spacing: 0.5px;
        &:focus {
            border: 1px solid var(--blue);
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
         margin: 0;
    }

    input[type=checkbox] {
        -webkit-print-color-adjust: exact;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 3px;
        background-color: #fff;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid #ced4da;
        color-adjust: exact;
        height: 1.1em;
        margin-top: 0.2em;
        vertical-align: top;
        width: 1.1em;
        cursor: pointer;
        margin-right: 8px;
        transform: translateY(0.5px);
        margin-right: 12px;
        width: 18px;
        height: 18px;
    }
    input[type=checkbox]:checked {
        background-color: var(--blue);
        border: none;
    }
    input[type=checkbox]:checked {
        background-image: url(${CheckboxIcon});
    }
    .Toastify__toast {
        padding-left: 20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    }
    .Toastify__toast-body {
        gap: 10px;
    }
    .overflow-hidden {
        overflow-x: hidden !important;
        overflow: unset !important;
    }
    .Dropdown-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }
    .Dropdown-menu {
        overflow: hidden;
        .Dropdown-option  {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
       @media (max-width: 800px) {
        z-index: 1000 !important;
        left: 26px;
        width: 85% !important;
       }
    }
    .flex-filter {
        display: grid;
        grid-template-columns: 200px 200px 120px;
        grid-gap: 20px;
        align-items: center;
        @media (max-width: 900px) {
            margin-top: 10px;
            grid-template-columns: 1fr;
            justify-content: center;
            width: 100%;
        }
    }
    .box {
        padding: 18px 24px;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 20px;
        transition: 0.3s all ease-in-out;
        overflow-x: auto;
        h5 {
            font-weight: 500;
            font-size: 18px;
        }
        @media (max-width: 768px) {
            padding: 15px 20px;
            overflow-x: auto;
            padding-bottom: 20px;
            td,
            th {
                min-width: 150px;
                width: auto;
                padding: 15px 20px;
            }
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        thead {
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 10;
            th {
                padding-top: 10px;
                padding-bottom: 20px;
            }
        }
        td, th {
            padding: 12px 0;
            @media (max-width: 1200px) {
                font-size: 13px;
            }
            @media (min-width: 1300px) {
                font-size: 14px;
            }
            @media (min-width: 1800px) {
                font-size: 14px;
            }
            
        }
        tr:nth-child(even) {
            background-color: rgba(0,0,0,0.03) !important;
        }
        tr {
            transition: 0.05s all ease-in-out;
        }
        tbody tr:hover {
            background-color: #f3f3f8;
            cursor: pointer;
        }
    }
    .select__control {
        padding: 8px;
        border: 1px solid #e5e5e5 !important;
        font-size: 15px !important;
        font-weight: 300 !important;
        border-radius: 12px !important;
    }
    .select__menu {
        border-radius: 8px !important;
        overflow: hidden;
    }
    .select__menu-list {
        padding: 0 !important;
        font-weight: 300 !important;
        font-size: 15px !important;
        cursor: pointer !important;
        .select__option:hover {
            background-color: rgba(53, 119, 241, 0.1) !important;
        }
        .select__option--is-selected {
            &:hover {
                background-color: var(--blue) !important;
            }
            background-color: var(--blue) !important;
        }
    }
    .error {
        letter-spacing: 0.5px !important;
        font-size: 300;
        font-size: 12px !important;
    }
    @media (max-width: 1000px) {
        ._rtm-container--open {
            display: block;
        }
    }
`;

export const Flex = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const PageWrapper = styled.div`
  padding: 30px 40px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 40px;
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #1b2559;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    p {
      display: block;
      width: 100%;
      display: flex;
      span {
        margin-left: 8px;
      }
    }
  }
  .red {
    color: var(--red);
    font-weight: 500;
  }
  .error {
    color: var(--red);
    letter-spacing: 0.3px;
    font-weight: 300;
    font-size: 12px;
    margin: 0;
    justify-content: left !important;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;
