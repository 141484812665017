import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background: #fff;
  padding: 50px 38px;
  height: 100vh;
  padding-bottom: 3%;
  position: sticky;
  top: 0;
  transition: 0.1s all ease-in-out;
  &.hidden {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 11;
    button {
      position: fixed;
      left: 0 !important;
    }
  }
  img {
    width: 150px;
  }
  a {
    font-size: 14px;
    display: flex;
    gap: 15px;
    padding: 10px;
    margin-bottom: 18px;
    color: #a3aed0;
    color: #000;
    align-items: center;
    font-weight: 500;
    position: relative;
    &.active {
      color: #fff;
      background-color: var(--blue);
      border-radius: 8px;
    }
  }
  @media (max-width: 1300px) {
    position: absolute;
    top: 0;
    left: 0%;
    z-index: 11;
    button {
      position: fixed !important;
      left: 0 !important;
      width: 30px !important;
      height: 30px !important;
    }
  }
  button {
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 0;
    right: -22px;
    top: 80px;
  }
  @media (max-width: 800px) {
    padding-bottom: 20%;
  }
  @media (max-width: 1200px) {
    button {
      display: inline-block;
    }
  }
`;

export const Links = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
