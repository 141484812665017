import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import {
  Flex,
  GlobalStyle,
  PageWrapper,
} from "./components/common/global/global";
import Menu from "./components/menu/menu";
import { AuthContext } from "./context/auth";
const Moderators = React.lazy(() => import("./pages/moderators/moderators"));
const Organizations = React.lazy(() =>
  import("./pages/organizations/organizations")
);
const Clients = React.lazy(() => import("./pages/clients/clients"));
const Services = React.lazy(() => import("./pages/services/services"));
const PosDevices = React.lazy(() => import("./pages/pos-devices/pos-devices"));
const Transactions = React.lazy(() =>
  import("./pages/transactions/transactions")
);
const Receips = React.lazy(() => import("./pages/receips/receips"));
const CreateClient = React.lazy(() =>
  import("./pages/create-client/create-client")
);
const Auth = React.lazy(() => import("./pages/auth/auth"));

function App() {
  const { isLogged } = useContext(AuthContext);

  useEffect(() => {
    localStorage.getItem("user") &&
      axios
        .get(`https://incom.7i.uz/api/`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((res) => {})
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            window.location.reload(false);
          }
        });
    localStorage.getItem("user") &&
      axios
        .get(`https://incom.7i.uz/api/organization?page=$1&limit=10000000`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((res) => {
          localStorage.setItem("organizations", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            window.location.reload(false);
          }
        });
    localStorage.getItem("user") &&
      axios
        .get(`https://incom.7i.uz/api/moderator?page=$1&limit=10000000`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((res) => {
          localStorage.setItem("moderators", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            window.location.reload(false);
          }
        });
    localStorage.getItem("user") &&
      axios
        .get(`https://incom.7i.uz/api/service?page=$1&limit=10000000`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access_token
            }`,
          },
        })
        .then((res) => {
          localStorage.setItem("services", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("user");
            window.location.reload(false);
          }
        });
  }, []);

  return (
    <>
      <BrowserRouter>
        <GlobalStyle />
        {isLogged ? (
          <>
            <Flex>
              <Menu />
              <PageWrapper>
                <Routes>
                  <Route path="/create-client" element={<CreateClient />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/pos-devices" element={<PosDevices />} />
                  {/* <Route path="/create-client" element={<CreateClient />} /> */}
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/receipts" element={<Receips />} />
                  <Route path="/moderators" element={<Moderators />} />
                  <Route path="*" element={<Organizations />} />
                </Routes>
              </PageWrapper>
            </Flex>
          </>
        ) : (
          <Auth />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
